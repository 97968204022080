import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { FiPlus } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { UseTrainers } from "../contexts/TrainersContext";

const Trainers = () => {
    const { trainers } = UseTrainers();
    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Trainers List</h3>
                            {(localStorage.getItem('type') === 'admin') ? (
                            <Link className="employee-add-btn" to="/trainer/add">
                            <IconContext.Provider value={{size: '18px', color: "#fff"}}>
                                <FiPlus />
                            </IconContext.Provider>
                                Add New
                            </Link>
                            ) : ''}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Table responsive hover>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Specializes</th>
                            <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {trainers.map(trainer => (
                            <tr key={trainer.id}>
                                <td>{trainer.username}</td>
                                <td>
                                    <div className="wh-profile">
                                        <div className="wh-caption">
                                            <p>{trainer.trainer.name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>{trainer.trainer.specializes}</td>
                                <td>{trainer.trainer.phone}</td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default Trainers
