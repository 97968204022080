import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../api';
import Switch from 'react-input-switch';
import headers from '../utils/headers'
import nowDate from '../utils/nowDate'

const AddUnavailable = () => {
    const [trainers, setTrainers] = useState([]);
    const [fullDay, setFullDay] = useState(true);
    const [startTime, setStartTime] = useState('10:00');
    const [endTime, setEndTime] = useState('15:00');
    const [date, setDate] = useState(nowDate());
    const [id, setId] = useState();

    useEffect(() => {
        loadTrainers();
    }, [])

    const loadTrainers = async () => {
        try {
            const {data} = await axios.get(api + '/users?role.name=trainer', {headers});
            setTrainers(data);
        } catch (err) {
            console.log(err.response);
        }
    }

    const createSlots = async (e) => {
        e.preventDefault();
        const reqData = {
            start_time: startTime + ':00',
            end_time: endTime + ':00',
            date: date,
            trainer: id
        }
        try {
            const url = fullDay ? '/unavailabledates' : '/unavailableslots';
            const {data} = await axios.post(api + url, reqData, {headers});
            window.location.href = '/unavailable'
        } catch (err) {
            console.log(err.response);
        }
    }

    return (
        <React.Fragment>
        <Form style={{width: '100%'}} onSubmit={createSlots}>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="single-activity-header">
                            <h3 className="dash-title">Add Unavailable Date/Slot</h3>
                            <div className="add-emp-btn-group">
                                <button className="employee-add-btn" type="submit">
                                    Save
                                </button>
                                <Link className="employee-add-btn cancel" to="/unavailable">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Form.Group as={Row} className="emp-form-row">
                        <Col>
                            <Form.Label>Trainer</Form.Label>
                            <Form.Control as="select" onChange={(e) => setId(e.target.value)}>
                                <option value="">Select Trainer</option>
                                {trainers.map(( trainer ) => (
                                    <option value={trainer.trainer.id} key={trainer.id}>{trainer.trainer.name} #{trainer.username}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="emp-form-row">
                        <Col>
                            <Form.Label>Start Time</Form.Label><br/>
                            <input style={{width: '100%', height: '40px', paddingLeft: '5px'}}  type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} disabled={fullDay} />
                            <div className="recur-module">
                                <p>Full Day</p>
                                <Switch on={true} off={false} value={fullDay} onChange={setFullDay} />
                            </div>
                        </Col>
                        <Col>
                            <Form.Label>End Time</Form.Label><br/>
                            <input style={{width: '100%', height: '40px', paddingLeft: '5px'}}  type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} disabled={fullDay}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="emp-form-row">
                        <Col>
                            <Form.Label>Select Month</Form.Label><br/>
                            <input style={{width: '100%', height: '40px', paddingLeft: '5px'}} type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                        </Col>
                    </Form.Group>
                </Row>
            </div>
        </Form>
        </React.Fragment>
    )
}

export default AddUnavailable