import React from 'react';
import PendingSessions from '../components/PendingSessions';
import TodaysClass from '../components/TodaysClass';
import TodaySessions from '../components/TodaySessions';

const Dashboard = () => {
    return (
        <React.Fragment>
            {(localStorage.getItem('type') === 'admin') ? (
            <PendingSessions />
            ): ''}
            <div style={{paddingBottom: '30px'}}></div>
            <TodaysClass />
            <div style={{paddingBottom: '30px'}}></div>
            {(localStorage.getItem('type') === 'admin') ? (
            <TodaySessions />
            ): ''}
        </React.Fragment>
    )
}

export default Dashboard