import {addZeroInFrontIfNeeded} from './Month'

const nowDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${addZeroInFrontIfNeeded(month)}-${addZeroInFrontIfNeeded(day)}`
}

export default nowDate