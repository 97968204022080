import axios from 'axios';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Alert } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { Link } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import api from '../api';
import { UseTrainers } from "../contexts/TrainersContext";
import headers from '../utils/headers'

const AddPT = () => {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState('10:00');
    const [members, setMembers] = useState();
    const [member, setMember] = useState();
    const [trainer, setTrainer] = useState();
    const [status, setStatus] = useState('approved');
    const [error, setError] = useState(false);
    const [type, setType] = useState('yoga');
    const { trainers } = UseTrainers();

    useEffect(() => {
        axios.get(api + '/users?role.name=member', {headers})
        .then(
            res => {
                setMembers(res.data);
                setLoading(false);
            }
        ).catch(
            error => {
                console.log(error.response);
            }
        );
    }, []);

    const trainerControl = (e) => {
        setTrainer(e.target.value);
    }
    const memberControl = (e) => {
        setMember(e.target.value);
    }
    const statusControl = (e) => {
        setStatus(e.target.value);
    }
    const typeControl = (e) => {
        setType(e.target.value);
    }

    const sessionSubmit = () => {
        const formattedDate = Moment(date).format('YYYY-MM-DD');
        const formattedTime = time + ':00.000';
        const data = {
            session_type: type,
            status: status,
            trainer: trainer,
            date: formattedDate,
            start_time: formattedTime,
            member: member,
            bookingData: [{trainer: trainer, date: formattedDate}]
        }
        axios.post(api + '/ptsessions', data, {headers})
        .then(
            res => {
                if(res.data[0].message){
                    throw new Error(res.data[0].message);
                } else {
                    window.location.href = '/personal-sessions';
                }
            }
        ).catch(
            error => {
                setError(error.message);
            }
        );
    }

    if(loading){
        return 'Loading...';
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="single-activity-header">
                            <h3 className="dash-title">Add New Personal Training Session</h3>
                            <div className="add-emp-btn-group">
                                <button className="employee-add-btn" onClick={sessionSubmit}>
                                    Save
                                </button>
                                <Link className="employee-add-btn cancel" to="/personal-sessions">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Form style={{width: '100%'}}>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Member</Form.Label>
                                <Form.Control as="select" onChange={memberControl}>
                                    <option value="">Select Member</option>
                                    {members.map(( member ) => (
                                    member.role.type === 'admin' ? '' : (
                                    <option value={member.id} key={member.id}>{member.member.name} #{member.member.erpid}</option>
                                    )
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Session Type</Form.Label>
                                <Form.Control as="select" onChange={typeControl}>
                                    <option value="yoga">Yoga</option>
                                    <option value="cardio">Cardio</option>
                                    <option value="boxing">Boxing</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Date</Form.Label><br/>
                                <DatePicker value={date} onChange={setDate} />
                            </Col>
                            <Col>
                                <Form.Label>Starting Time</Form.Label><br/>
                                <TimePicker value={time} onChange={setTime} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Trainer</Form.Label>
                                <Form.Control as="select" onChange={trainerControl}>
                                    <option value="">Select Trainer</option>
                                    {trainers.map(( trainer ) => (
                                        <option value={trainer.trainer.id} key={trainer.id}>{trainer.trainer.name} #{trainer.username}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" onChange={statusControl}>
                                    <option value="approved">Approved</option>
                                    <option value="pending">Pending</option>
                                    <option value="cancelled">Cancelled</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
            </div>
            {error && (
                <Alert style={{marginTop: '20px'}} variant="danger">{error}</Alert>
            )}
        </React.Fragment>
    )
}

export default AddPT