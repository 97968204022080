import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { FiPlus } from "react-icons/fi";
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import api from '../api';
import headers from '../utils/headers'

const Progress = () => {
    const [member, setMember] = useState();
    const [memberDetails, setMemberDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const getMemberDetails = async () => {
        try {
            const {data} = await axios.get(api + '/memberdetails?_sort=created_at:desc&member.id=' + id, {headers});
            setMemberDetails(data);
        } catch (err) {
            console.log(err.response);
        }
    }

    const getMemberData = async () => {
        try {
            const {data} = await axios.get(api + '/members/' + id, {headers});
            setMember(data);
            getMemberDetails();
            setLoading(false);
        } catch (err) {
            console.log(err.response);
        }
    }

    useEffect(() => {
        getMemberData();
    }, []);


    if(loading) {
        return 'Loading...';
    }
    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Progress of {member.name}</h3>
                            {(localStorage.getItem('type') === 'admin') ? (
                            <Link className="employee-add-btn" to={"/progress/add/" + id}>
                            <IconContext.Provider value={{size: '18px', color: "#fff"}}>
                                <FiPlus />
                            </IconContext.Provider>
                                Add New
                            </Link>
                            ) : ''}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Table responsive hover>
                        <thead>
                            <tr>
                            <th>Date</th>
                            <th>Weight</th>
                            <th>Height</th>
                            <th>Chest</th>
                            <th>Hip</th>
                            <th>Waist</th>
                            <th>Body Fat</th>
                            <th>BMI</th>
                            </tr>
                        </thead>
                        <tbody>
                            {memberDetails.map(progress => (
                            <tr key={progress.id}>
                                <td><Moment format="DD MMMM, YYYY">{progress.created_at}</Moment></td>
                                <td>{progress.weight} <small>kg</small></td>
                                <td>{progress.height} <small>ft</small></td>
                                <td>{progress.chest}"</td>
                                <td>{progress.hip}"</td>
                                <td>{progress.waist}"</td>
                                <td>{progress.body_fat}%</td>
                                <td>{progress.bmi}</td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default Progress