import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import api from "../api";

const TrainersContext = createContext();

export function TrainersContextProvider({ children }) {
  const [trainers, setTrainers] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${api}/users?role.name=trainer`, {
          headers: {
              'authorization': 'Bearer ' + localStorage.getItem('token'),
              'content-type': 'application/json'
          }
      });
      setTrainers(data);
    }
    fetchData();
  }, []);
  return (
    <TrainersContext.Provider
      value={{
        trainers
      }}
    >
      {children}
    </TrainersContext.Provider>
  );
}

export function UseTrainers() {
  const context = useContext(TrainersContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
