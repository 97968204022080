import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { FiPlus } from "react-icons/fi";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import api from '../api';
import { UseTrainers } from "../contexts/TrainersContext";
import tConvert from '../utils/TConvert'
import defDay from '../utils/defDay'
import headers from '../utils/headers'
import nowDate from '../utils/nowDate'

const TrainerSchedules = () => {
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState(true);
    const { trainers } = UseTrainers();

    const getSchedules = async (id) => {
        const url = (id === 'all') ? api + `/schedules?expiry_date_gte=${nowDate()}` : api + `/schedules?expiry_date_gte=${nowDate()}&trainer.id=${id}`;
        try {
            const {data} = await axios.get(url, {headers})
            setSchedules(data);
            setLoading(false);
        } catch (err) {
            console.log(err.response);
        }
    }

    useEffect(() => {
        getSchedules('all')
    }, [])

    const onTrainerChange = (e) => {
        getSchedules(e.target.value);
    }

    if(loading) {
        return 'Loading...';
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Trainer Schedules</h3>
                            {(localStorage.getItem('type') === 'admin') ? (
                            <Link className="employee-add-btn" to="/schedule/add">
                            <IconContext.Provider value={{size: '18px', color: "#fff"}}>
                                <FiPlus />
                            </IconContext.Provider>
                                Add New
                            </Link>
                            ) : ''}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Form.Control as="select" style={{marginBottom: '20px'}} onChange={onTrainerChange}>
                        <option value="all">All Trainers</option>
                        {trainers.map(( trainer ) => (
                            <option value={trainer.trainer.id} key={trainer.id}>{trainer.trainer.name} #{trainer.username}</option>
                        ))}
                    </Form.Control>
                </Row>
                <Row>
                    <Table responsive hover>
                        <thead>
                            <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Day</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedules.map(schedule => (
                            <tr key={schedule.id}>
                                <td>
                                    <div className="wh-profile">
                                        <div className="wh-caption">
                                            <p>{schedule.trainer.name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td><Moment format="Do MMMM, YYYY">{schedule.effective_date}</Moment> - <Moment format="Do MMMM, YYYY">{schedule.expiry_date}</Moment></td>
                                <td>{tConvert(schedule.start_time)} - {tConvert(schedule.end_time)}</td>
                                <td>{defDay(schedule.day)}</td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default TrainerSchedules
