import axios from 'axios';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Switch from 'react-input-switch';
import { Link } from 'react-router-dom';
import api from '../api';
import { UseTrainers } from "../contexts/TrainersContext";
import headers from '../utils/headers'

const AddClass = () => {
    const [day, setDay] = useState(0);
    const [time, setTime] = useState('10:00');
    const [endTime, setEndTime] = useState('10:00');
    const [className, setClassName] = useState();
    const [classType, setClassType] = useState('Yoga');
    const [duration, setDuration] = useState(20);
    const [capacity, setCapacity] = useState(10);
    const [location, setLocation] = useState('Yoga Room 1');
    const [trainer, setTrainer] = useState();
    const [recur, setRecur] = useState(true);
    const { trainers } = UseTrainers();

    const ClassSubmit = async (e) => {
        e.preventDefault();
        const formattedTime = time + ':00.000';
        const formattedEndTime = endTime + ':00.000';
        const reqData = {
            class_name: className,
            class_type: classType,
            day: day,
            recur: recur,
            start_time: formattedTime,
            end_time: formattedEndTime,
            duration: duration,
            capacity: capacity,
            location: location,
            trainer: trainer
        }
        try {
            const {data} = await axios.post(api + '/classes', reqData, {headers});
            window.location.href = '/classes';
        } catch (err) {
            console.log(err.response);
        }
    }
    
    return (
        <React.Fragment>
        <Form style={{width: '100%'}} onSubmit={ClassSubmit}>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="single-activity-header">
                            <h3 className="dash-title">Add New Class</h3>
                            <div className="add-emp-btn-group">
                                <button className="employee-add-btn" type="submit">
                                    Save
                                </button>
                                <Link className="employee-add-btn cancel" to="/classes">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Class Name</Form.Label>
                                <Form.Control type="text" placeholder="Yoga 101, Swimming Beginner" onChange={(e) => setClassName(e.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label>Class Type</Form.Label>
                                <Form.Control as="select" onChange={(e) => setClassType(e.target.value)} required>
                                    <option value="yoga">Yoga</option>
                                    <option value="cardio">Cardio</option>
                                    <option value="boxing">Boxing</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Day</Form.Label><br/>
                                <Form.Control as="select" onChange={(e) => setDay(e.target.value)} required>
                                    <option value={0}>Sunday</option>
                                    <option value={1}>Monday</option>
                                    <option value={2}>Tuesday</option>
                                    <option value={3}>Wednesday</option>
                                    <option value={4}>Thursday</option>
                                    <option value={5}>Friday</option>
                                    <option value={6}>Saturday</option>
                                </Form.Control>
                                <div className="recur-module">
                                    <p>Recurring</p>
                                    <Switch on={true} off={false} value={recur} onChange={setRecur} />
                                </div>
                            </Col>
                            <Col>
                                <Form.Label>Start Time</Form.Label><br/>
                                <input style={{width: '100%', height: '40px', paddingLeft: '5px'}}  type="time" value={time} onChange={(e) => setTime(e.target.value)}/>
                            </Col>
                            <Col>
                                <Form.Label>End Time</Form.Label><br/>
                                <input style={{width: '100%', height: '40px', paddingLeft: '5px'}}  type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Duration (minutes)</Form.Label>
                                <Form.Control as="select" onChange={(e) => setDuration(e.target.value)} required>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="35">35</option>
                                    <option value="40">40</option>
                                    <option value="45">45</option>
                                    <option value="50">50</option>
                                    <option value="55">55</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Capacity</Form.Label>
                                <Form.Control type="text" placeholder="10/20/30" onChange={(e) => setCapacity(e.target.value)} required />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Location</Form.Label>
                                <Form.Control as="select" onChange={(e) => setLocation(e.target.value)}>
                                    <option value="Yoga Room 1">Yoga Room 1</option>
                                    <option value="Spa Room 5">Spa Room 5</option>
                                    <option value="Lifting Room 3">Lifting Room 3</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Trainer</Form.Label>
                                <Form.Control as="select" onChange={(e) => setTrainer(e.target.value)} required>
                                    <option value="">Select Trainer</option>
                                    {trainers.map(( trainer ) => (
                                        <option value={trainer.trainer.id} key={trainer.id}>{trainer.trainer.name} #{trainer.username}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                </Row>
            </div>
        </Form>
        </React.Fragment>
    )
}

export default AddClass