import React from 'react';
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import elevateLogo from '../media/elevatelogo_white.png';

const Header = () => {

    const logOut = () => {
        localStorage.clear();
        window.location.href = '/';
    }

    return (
        <Container className="header" fluid>
            <Row>
                <Col md={2} className="no-pad">
                    <div className="branding">
                        <h3>
                            <img src={elevateLogo} style={{maxWidth: '70%'}} alt=""/>
                        </h3>
                    </div>
                </Col>
                <Col md={10} className="no-pad">
                    <div className="header-content">
                        <div className="header-profile">
                            <DropdownButton id="dropdown-basic-button" title={localStorage.getItem('type')}>
                                <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Header