import React, { useState,  useEffect } from 'react';
import {Row, Col, Form} from 'react-bootstrap';
import {Link, useParams} from 'react-router-dom';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import { UseTrainers } from "../contexts/TrainersContext";
import axios from 'axios';
import api from '../api'
import headers from '../utils/headers'

const EditClass = () => {
    const [className, setClassName] = useState();
    const [classType, setClassType] = useState();
    const [duration, setDuration] = useState();
    const [capacity, setCapacity] = useState();
    const [location, setLocation] = useState();
    const [trainer, setTrainer] = useState();
    const { trainers } = UseTrainers();
    const { classid } = useParams();

    useEffect(() => {
        loadClassData();
    }, [])

    const loadClassData = async () => {
        try {
            const {data} = await axios.get(api + '/classes/' + classid, {headers});
            setClassName(data.class_name);
            setClassType(data.class_type);
            setDuration(data.duration);
            setLocation(data.location);
            setTrainer(data.trainer);
            setCapacity(data.capacity);
        } catch (err) {
            console.log(err.response);
        }
    }

    const ClassSubmit = async () => {
        const reqData = {
            class_name: className,
            class_type: classType,
            duration: duration,
            capacity: capacity,
            location: location,
            trainer: trainer
        }
        try {
            const {data} = await axios.put(api + '/classes/' + classid, reqData, {headers});
            window.location.href = '/classes';
        } catch (err) {
            console.log(err.response);
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="single-activity-header">
                            <h3 className="dash-title">Edit Class</h3>
                            <div className="add-emp-btn-group">
                                <button className="employee-add-btn" onClick={ClassSubmit}>
                                    Save
                                </button>
                                <Link className="employee-add-btn cancel" to="/classes">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Form style={{width: '100%'}}>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Class Name</Form.Label>
                                <Form.Control type="text" placeholder="Yoga 101, Swimming Beginner" defaultValue={className} onChange={(e) => setClassName(e.target.value)} />
                            </Col>
                            <Col>
                                <Form.Label>Class Type</Form.Label>
                                <Form.Control as="select" value={classType} onChange={(e) => setClassType(e.target.value)}>
                                    <option value=""></option>
                                    <option value="yoga">Yoga</option>
                                    <option value="cardio">Cardio</option>
                                    <option value="boxing">Boxing</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Date</Form.Label><br/>
                                <DatePicker disabled />
                            </Col>
                            <Col>
                                <Form.Label>Starting Time</Form.Label><br/>
                                <TimePicker disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Duration (minutes)</Form.Label>
                                <Form.Control as="select" value={duration} onChange={(e) => setDuration(e.target.value)}>
                                    <option value=""></option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                    <option value="35">35</option>
                                    <option value="40">40</option>
                                    <option value="45">45</option>
                                    <option value="50">50</option>
                                    <option value="55">55</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Capacity</Form.Label>
                                <Form.Control type="text" placeholder="10/20/30" defaultValue={capacity} onChange={(e) => setCapacity(e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Location</Form.Label>
                                <Form.Control as="select" onChange={(e) => setLocation(e.target.value)} value={location}>
                                    <option value="Yoga Room 1">Yoga Room 1</option>
                                    <option value="Spa Room 5">Spa Room 5</option>
                                    <option value="Lifting Room 3">Lifting Room 3</option>
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Trainer</Form.Label>
                                <Form.Control as="select" onChange={(e) => setTrainer(e.target.value)} value={trainer}>
                                    <option value="">Select Trainer</option>
                                    {trainers.map(( trainer ) => (
                                        <option value={trainer.TrainerName + ' #' + trainer.TrainerId} key={trainer.TrainerId}>{trainer.TrainerName} #{trainer.TrainerId}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default EditClass