import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ClockIcon from '../media/clock.svg';
import WarnIcon from '../media/warning.svg';
import DashboardIcon from '../media/dashboard.svg';
import GymIcon from '../media/gym.svg';
import UserIcon from '../media/people.svg';
import TrainingIcon from '../media/training.svg';

export default class Sidebar extends Component {
    render() {
        return (
            <div className="nav-sidebar">
                <ul>
                    <li><NavLink to="/dashboard"><img style={{width: '20px'}}  src={DashboardIcon} alt=""/> <span>Dashboard</span></NavLink></li>
                    <li><NavLink to="/classes"><img style={{width: '20px'}} src={GymIcon} alt=""/> <span>Classes</span></NavLink></li>
                    <li><NavLink to="/personal-sessions"><img style={{width: '20px'}}  src={TrainingIcon} alt=""/> <span>Training Sessions</span></NavLink></li>
                    {(localStorage.getItem('type') === 'admin') ? (
                    <li><NavLink to="/trainers"><img style={{width: '20px'}}  src={UserIcon} alt=""/> <span>Trainers</span></NavLink></li>
                    ): ''}
                    {(localStorage.getItem('type') === 'admin') ? (
                    <li><NavLink to="/trainer-schedules"><img style={{width: '20px'}}  src={ClockIcon} alt=""/> <span>Trainer Schedules</span></NavLink></li>
                    ): ''}
                    {(localStorage.getItem('type') === 'admin') ? (
                    <li><NavLink to="/unavailable"><img style={{width: '20px'}}  src={WarnIcon} alt=""/> <span>Unavailability</span></NavLink></li>
                    ): ''}
                    <li><NavLink to="/members"><img style={{width: '20px'}}  src={UserIcon} alt=""/> <span>Members</span></NavLink></li>
                </ul>
            </div>
        )
    }
}
