// PostgreSQL

// export const tConvert = (time) => {
//     time = time.slice(0, -3);
//     time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];      
//     if (time.length > 1) {
//         time = time.slice (1);
//         time[5] = +time[0] < 12 ? ' AM' : ' PM';
//         time[0] = +time[0] % 12 || 12;
//     }
//     return time.join ('');
// }

// SQLite

const tConvert = (time) => {
    time = time.slice(0, -7);
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];      
    if (time.length > 1) {
        time = time.slice (1);
        time.pop();
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    }
    return time.join ('');
}

export default tConvert;