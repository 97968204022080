import React, {useState} from 'react'
import tConvert from '../utils/TConvert'
import {Card, Accordion} from 'react-bootstrap'
import defDay from '../utils/defDay'
import axios from 'axios'
import api from '../api'
import Session from './Session'
import headers from '../utils/headers'
import nowDate from '../utils/nowDate'

const Class = ({eclass, index}) => {
    const [sessions, setSessions] = useState();
    
    const reqSessions = async () => {
        try {
            const {data} = await axios.get(api + `/classsessions?class.id=${eclass.id}&date_gte=${nowDate()}`, {headers});
            setSessions(data);
        } catch (err) {
            console.log(err.response)
        }
    }

    return (
        <Card>
            <Accordion.Toggle onClick={reqSessions} as={Card.Header} eventKey={index + 1} className="class-accordion-toggle">
                <div>
                    <p>{eclass.class_name} <small>#{eclass.id}</small></p>
                </div>        
                <div>
                {defDay(eclass.day)} <small><b>{eclass.recur ? 'Recurring' : ''}</b></small><br/>
                <small>(Start Time: {eclass.start_time ? tConvert(eclass.start_time) : 'N/A'})</small>
                </div>
                <div>{eclass.class_type}</div>
                <div>{eclass.trainer.name}</div>
                <div>{eclass.duration} mins</div>
                <div>{eclass.location}</div>
                <div>{eclass.capacity}</div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index + 1}>
            <Card.Body>
                <Accordion className="class-accordion">
                    {sessions ? sessions.map((session, index) => <Session session={session} index={index + 1} key={index} />) : ''}
                </Accordion>
            </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default Class
