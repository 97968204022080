import axios from 'axios';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import api from '../api';
import headers from '../utils/headers'

const AddProgress = () => {
    const [weight, setWeight] = useState();
    const [height, setHeight] = useState();
    const [chest, setChest] = useState();
    const [hip, setHip] = useState();
    const [bodyFat, setBodyFat] = useState();
    const [waist, setWaist] = useState();
    const [bmi, setBmi] = useState();
    const { id } = useParams();

    const ClassSubmit = async (e) => {
        e.preventDefault();
        const reqData = {
            weight: weight,
            height: height,
            chest: chest,
            hip: hip,
            body_fat: bodyFat,
            waist: waist,
            bmi: bmi,
            member: id
        }
        try {
            const {data} = await axios.post(api + '/memberdetails', reqData, {headers});
            window.location.href = '/progress/' + id;
        } catch (err) {
            console.log(err.response);
        }
    }
    
    return (
        <React.Fragment>
        <Form style={{width: '100%'}} onSubmit={ClassSubmit}>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="single-activity-header">
                            <h3 className="dash-title">Add New Progress</h3>
                            <div className="add-emp-btn-group">
                                <button className="employee-add-btn" type="submit">
                                    Save
                                </button>
                                <Link className="employee-add-btn cancel" to={"/progress/" + id}>
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Form.Group as={Row} className="emp-form-row">
                        <Col>
                            <Form.Label>Weight</Form.Label>
                            <Form.Control type="text" placeholder="78" onChange={(e) => setWeight(e.target.value)} required />
                        </Col>
                        <Col>
                            <Form.Label>Height</Form.Label>
                            <Form.Control type="text" placeholder="5.8" onChange={(e) => setHeight(e.target.value)} required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="emp-form-row">
                        <Col>
                            <Form.Label>Chest</Form.Label>
                            <Form.Control type="text" placeholder="18" onChange={(e) => setChest(e.target.value)} required />
                        </Col>
                        <Col>
                            <Form.Label>Hip</Form.Label>
                            <Form.Control type="text" placeholder="20" onChange={(e) => setHip(e.target.value)} required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="emp-form-row">
                        <Col>
                            <Form.Label>Body Fat</Form.Label>
                            <Form.Control type="text" placeholder="40" onChange={(e) => setBodyFat(e.target.value)} required />
                        </Col>
                        <Col>
                            <Form.Label>Waist</Form.Label>
                            <Form.Control type="text" placeholder="18" onChange={(e) => setWaist(e.target.value)} required />
                        </Col>
                        <Col>
                            <Form.Label>BMI</Form.Label>
                            <Form.Control type="text" placeholder="52" onChange={(e) => setBmi(e.target.value)} required />
                        </Col>
                    </Form.Group>
                </Row>
            </div>
        </Form>
        </React.Fragment>
    )
}

export default AddProgress