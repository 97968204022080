import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const Layout = ({children}) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isAdmin, setAdmin] = useState(false);

    useEffect(() => {
        setLoggedIn(localStorage.getItem('login'));
        if(localStorage.getItem('type') === 'admin' || localStorage.getItem('type') === 'trainer'){
            setAdmin(true);
        }
        if(!localStorage.getItem('login')){
            window.location.href = '/';
        }
    }, [])

    const goBack = () => {
        localStorage.clear();
        window.location.href = '/';
    }

    return (
        <React.Fragment>
            {isLoggedIn && isAdmin ? (
                <React.Fragment>
                    <Header />
                    <div className="layout">
                        <Container fluid>
                            <Row>
                                <Col md={2} className="no-pad">
                                {isLoggedIn && isAdmin ? (<Sidebar />) : ''}
                                </Col>
                                <Col md={10} className="no-pad">
                                    <div className="layout-wrapper">
                                    {children}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            ): (
                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
                    <h3>Not Authorized</h3>
                    <button onClick={goBack}>Go Back</button>
                </div>
            )}
            
        </React.Fragment>
    )
};

export default Layout;