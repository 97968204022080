import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import Moment2 from 'react-moment';
import api from '../api';
import tConvert from '../utils/TConvert'
import headers from '../utils/headers'
import nowDate from '../utils/nowDate'

const TodaySessions = () => {
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState();

    const reqTodaysSessions = async () => {
        try {
            const {data} = await axios.get(api + '/ptsessions?status=approved&date=' + nowDate(), {headers});
            setSessions(data);
            setLoading(false);
        } catch (err) {
            console.log(err.response);
        }
    }

    useEffect(() => {
        reqTodaysSessions();
    }, []);

    const StatusColor = (status) => {
        if(status === 'pending'){
            return '#c7c73f';
        } else if(status === 'cancelled'){
            return 'red';
        } else {
            return 'green';
        }
    }

    if(loading){
        return 'Loading...';
    }
    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Today's PT Sessions</h3>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Table responsive hover>
                        <thead>
                            <tr>
                            <th>Client</th>
                            <th>Confirmed Time</th>
                            <th>Session Type</th>
                            <th>Trainer</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sessions.map(session => (
                                <tr key={session.id}>
                                    <td>
                                        <div className="wh-profile">
                                            <div className="wh-caption">
                                                <p>
                                                    {session.member.name}<br/>
                                                    <small>{session.member.erpid}</small>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td><Moment2 format="Do MMMM">{session.date}</Moment2> <br/> <small>({session.start_time ? tConvert(session.start_time) : 'N/A'})</small></td>
                                    <td style={{textTransform: 'capitalize'}}>{session.session_type}</td>
                                    <td>{session.trainer.name}</td>
                                    <td>
                                        <span style={{color: StatusColor(session.status), textTransform: 'capitalize'}}>{session.status}</span>
                                    </td>
                                </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default TodaySessions
