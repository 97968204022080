import React, {useState} from 'react'
import {Card, Accordion, Form} from 'react-bootstrap'
import Moment from 'react-moment'
import api from '../api'
import axios from 'axios'
import headers from '../utils/headers'

const Session = ({session, index}) => {
    const [attended, setAttended] = useState(new Set());
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitAttendances, setSubmitAttendances] = useState([]);

    const submitAttendance = async (e) => {
        e.preventDefault();
        const attendedMembers = [...attended];
        const req = {
            id: session.id,
            members: attendedMembers
        }
        try {
            const {data} = await axios.post(api + '/attendances', req, {headers});
            checkAttendance();
            setIsSubmitted(true);
        } catch (err) {
            console.log(err);
        }

    }

    const checkAttendance = async () => {
        try {
            const {data} = await axios.get(api + '/attendances?class_session=' + session.id, {headers});
            setSubmitAttendances(data)
            if(!data.length){
                setIsSubmitted(true)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const onChecked = (e) => {
        const value = parseInt(e.target.value);
        if(attended.has(value)){
            attended.delete(value);
        } else {
            attended.add(value);
        }
    }
    return (
        <Card>
            <Accordion.Toggle onClick={checkAttendance} as={Card.Header} eventKey={index} className="class-accordion-toggle">
                <Moment format="Do MMMM, YYYY">{session.date}</Moment>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
            <Card.Body>
                <div className="session-check">
                    <Form onSubmit={submitAttendance}>
                        {!isSubmitted ? (
                            submitAttendances.map((attend, index) => 
                            <Form.Check 
                                type="checkbox"
                                id={'check' + index}
                                label={attend.member.name}
                                value={attend.member.id}
                                key={index}
                                style={{marginBottom: '10px'}}
                                name={attend.member.name}
                                checked={attend.attended}
                                onChange={onChecked}
                            />
                            )
                        ) : ''}
                        {isSubmitted && session.members.map((member, index) => 
                        <Form.Check 
                            type="checkbox"
                            id={'check' + index}
                            label={member.name}
                            value={member.id}
                            key={index}
                            style={{marginBottom: '10px'}}
                            name={member.name}
                            onChange={onChecked}
                        />
                        )}
                        {isSubmitted && (
                        <button className="employee-add-btn" type="submit" style={{marginTop: '10px'}}>Submit</button>
                        )}
                    </Form>
                </div>
            </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default Session
