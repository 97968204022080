import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import api from '../api';
import tConvert from '../utils/TConvert'
import headers from '../utils/headers'
import defDay from '../utils/defDay'
import nowDate from '../utils/nowDate'

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [classes, setClasses] = useState();

    const reqTodaysClass = async (role) => {
        const url = (role === 'admin') ? api + '/classes?class_sessions.date=' + nowDate() : api + '/classes?class_sessions.date=' + nowDate() + '&trainer.user=' + localStorage.getItem('id');
        try {
            const {data} = await axios.get(url, {headers});
            setClasses(data);
            setLoading(false);
        } catch (err) {
            console.log(err.response);
        }
    }

    useEffect(() => {
        reqTodaysClass(localStorage.getItem('type'));
    }, []);

    if(loading){
        return 'Loading...';
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Today's Classes</h3>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Table responsive hover>
                        <thead>
                            <tr>
                            <th>Class</th>
                            <th>Date & Time</th>
                            <th>Type</th>
                            <th>Trainer</th>
                            <th>Duration</th>
                            <th>Location</th>
                            <th>Capacity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {classes.map( eclass => {
                                return (
                                <tr key={eclass.id}>
                                    <td>
                                        <div className="wh-profile">
                                            <div className="wh-caption">
                                                <p>{eclass.class_name} <small>#{eclass.id}</small></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {defDay(eclass.day)} <small><b>{eclass.recur ? 'Recurring' : ''}</b></small><br/>
                                        <small>(Start Time: {eclass.start_time ? tConvert(eclass.start_time) : 'N/A'})</small>
                                    </td>
                                    <td>{eclass.class_type}</td>
                                    <td>{eclass.trainer.name}</td>
                                    <td>{eclass.duration} mins</td>
                                    <td>{eclass.location}</td>
                                    <td>{eclass.capacity}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default Dashboard