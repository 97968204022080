import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import { MembersContextProvider } from "./contexts/MembersContext";
import { TrainersContextProvider } from "./contexts/TrainersContext";
import AddClass from './pages/AddClass';
import AddPT from './pages/AddPT';
import AddSchedule from './pages/AddSchedule';
import AddUnavailable from './pages/AddUnavailable';
import AddTrainer from './pages/AddTrainer';
import Classes from './pages/Classes';
import Dashboard from './pages/Dashboard';
import EditClass from './pages/EditClass';
import Login from './pages/Login';
import Members from './pages/Members';
import PersonalSessions from './pages/PersonalSessions';
import Trainers from './pages/Trainers';
import TrainerSchedules from './pages/TrainerSchedules';
import Unavailable from './pages/Unavailable';
import Progress from './pages/Progress';
import AddProgress from './pages/AddProgress';

function App() {
  return (
    <div className="App">
      <MembersContextProvider>
        <TrainersContextProvider>
          <Router>
              <Route exact path="/" component={Login} />
              <Route exact path="/dashboard">
                <Layout>
                  <Dashboard />
                </Layout>
              </Route>
              <Route exact path="/classes">
                <Layout>
                  <Classes />
                </Layout>
              </Route>
              <Route exact path="/class/add">
                <Layout>
                  <AddClass />
                </Layout>
              </Route>
              <Route exact path="/class/edit/:classid">
                <Layout>
                  <EditClass />
                </Layout>
              </Route>
              <Route exact path="/personal-sessions">
                <Layout>
                  <PersonalSessions />
                </Layout>
              </Route>
              <Route exact path="/personal-session/add">
                <Layout>
                  <AddPT />
                </Layout>
              </Route>
              <Route exact path="/members">
                <Layout>
                  <Members />
                </Layout>
              </Route>
              <Route exact path="/trainers">
                <Layout>
                  <Trainers />
                </Layout>
              </Route>
              <Route exact path="/trainer/add">
                <Layout>
                  <AddTrainer />
                </Layout>
              </Route>
              <Route exact path="/trainer-schedules">
                <Layout>
                  <TrainerSchedules />
                </Layout>
              </Route>
              <Route exact path="/schedule/add">
                <Layout>
                  <AddSchedule />
                </Layout>
              </Route>
              <Route exact path="/unavailable">
                <Layout>
                  <Unavailable />
                </Layout>
              </Route>
              <Route exact path="/unavailable/add">
                <Layout>
                  <AddUnavailable />
                </Layout>
              </Route>
              <Route exact path="/progress/:id">
                <Layout>
                  <Progress />
                </Layout>
              </Route>
              <Route exact path="/progress/add/:id">
                <Layout>
                  <AddProgress />
                </Layout>
              </Route>
          </Router>
        </TrainersContextProvider>
      </MembersContextProvider>
    </div>
  );
}

export default App;
