import React from 'react'
import {Link} from 'react-router-dom'

const Member = ({member}) => {
    return (
        <tr key={member.erpid}>
            <td>{member.erpid}</td>
            <td>
                <div className="wh-profile">
                    <div className="wh-caption">
                        <p>{member.name}</p>
                    </div>
                </div>
            </td>
            <td>{member.phone}</td>
            <td>
                <button className="activity-button"><Link style={{textDecoration: 'none'}} to={'/progress/' + member.id}>View Progress</Link></button>
            </td>
        </tr>
    )
}

export default Member