export const addZeroInFrontIfNeeded = (number) => {
    if (number < 10) return `0${number}`
    return number
}

const formatDateToString = (date) => {
    const year = date.getFullYear()
    const month = addZeroInFrontIfNeeded(date.getMonth() + 1)
    const day = addZeroInFrontIfNeeded(date.getDate())
    return `${year}-${month}-${day}`
}

export const lastDateOfTheMonth = (date) => {
    let year = date.getFullYear()
    let month = date.getMonth() + 2
    if (month > 12) {
      month = month % 12
      year = year + 1
    }
    let lastDate = new Date(`${year}-${month}-01 00:00:01`)
    lastDate.setDate(lastDate.getDate() - 1)
  
    lastDate = formatDateToString(lastDate)
    return lastDate
}