import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";
import api from "../api";

const MembersContext = createContext();

export function MembersContextProvider({ children }) {
  const [members, setMembers] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `${api}/members`, {
          headers: {
              'authorization': 'Bearer ' + localStorage.getItem('token'),
              'content-type': 'application/json'
          }
      }
      );
      setMembers(data);
    }
    fetchData();
  }, []);
  return (
    <MembersContext.Provider
      value={{
        members
      }}
    >
      {children}
    </MembersContext.Provider>
  );
}

export function UseMembers() {
  const context = useContext(MembersContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
