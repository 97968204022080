import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import api from '../api';
import elevateLogo from '../media/elevatelogo.png';
import loginBG from '../media/login.png';

const Login = () => {
    const [identifier, setIdentifier] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if(localStorage.getItem('login')){
            window.location.href = '/dashboard'
        }
    })

    const loginAction = async () => {
        try {
            const {data} = await axios.post(api + '/auth/local', {
                identifier: identifier,
                password: password
            });
            localStorage.setItem('login', true);
            localStorage.setItem('token', data.jwt);
            localStorage.setItem('type', data.user.role.type);
            localStorage.setItem('id', data.user.id);
            setError(false);
            if(data.user.role.type === 'admin' || data.user.role.type === 'trainer') {
                window.location.href="/dashboard";
            } else {
                setError('Not Authorized');
            }
        } catch (err) {
            setError(err.response.data.message[0].messages[0].message);
        }
    }

    return (
        <div className="login-wrapper container-fluid">
            <Row className="align-content-md-center" style={{minHeight: '100vh'}}>
                <Col md={8} style={{alignSelf: 'center', textAlign: 'center'}}>
                    <img src={loginBG} alt="" style={{maxWidth: "70%"}} />
                </Col>
                <Col md={4} style={{alignSelf: 'center'}}>
                    <div className="login-form">
                        <div className="login-logo">
                            <img src={elevateLogo} alt=""/>
                            <p>Welcome! Please login to your account.</p>
                        </div>
                        <div>
                            <input className="login-control" type="text" placeholder="Username/Email" onChange={(e) => setIdentifier(e.target.value)} />
                            <input className="login-control" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            <button onClick={loginAction}>Login</button>
                            {error ? (
                                <Alert style={{marginTop: '15px'}} variant='danger'>
                                    {error}
                                </Alert>
                            ) : ''}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login

