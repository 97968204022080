import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Col, Row, Accordion } from 'react-bootstrap';
import { IconContext } from "react-icons";
import { FiPlus } from "react-icons/fi";
import { Link } from 'react-router-dom';
import api from '../api';
import Class from '../components/Class'
import headers from '../utils/headers'

const Classes = () => {
    const [loading, setLoading] = useState(true);
    const [classes, setClasses] = useState();

    useEffect(() => {
        const isAdmin = localStorage.getItem('type') === 'admin';
        if(isAdmin){
            reqClassData('/classes');
        } else {
            reqClassData('/classes?trainer.user=' + localStorage.getItem('id'));
        }
    }, []);

    const reqClassData = async (param) => {
        try {
            const {data} = await axios.get(api + param, {headers});
            setClasses(data);
            setLoading(false);
        } catch (err) {
            console.log(err.response);
        }
    }

    if(loading){
        return 'Loading...';
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Classes</h3>
                            {(localStorage.getItem('type') === 'admin') ? (
                            <Link className="employee-add-btn" to="/class/add">
                            <IconContext.Provider value={{size: '18px', color: "#fff"}}>
                                <FiPlus />
                            </IconContext.Provider>
                                Add New
                            </Link>
                            ) : ''}
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Accordion className="class-accordion">
                        {classes.map( (eclass, index) => <Class eclass={eclass} key={eclass.id} index={index} />)}
                    </Accordion>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default Classes