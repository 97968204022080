import React, { useState, useEffect } from 'react';
import {Row, Col, Table} from 'react-bootstrap';
import Moment from 'react-moment';
import axios from 'axios';
import {SkyLightStateless} from 'react-skylight';
import api from '../api'
import tConvert from '../utils/TConvert'
import headers from '../utils/headers'

const PendingSessions = () => {
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState();

    const [cancelPopup, setCancelPopup] = useState({visible: false, session: []});
    const [approvePopup, setApprovePopup] = useState({visible: false, session: []});

    const loadSession = async () => {
        try {
            const {data} = await axios.get(api + '/ptsessions?status=pending&_sort=created_at:desc', {headers});
            setSessions(data);
            setLoading(false);
        } catch (err) {
            console.log(err.response);
        }
    }

    useEffect(() => {
        loadSession();
        setInterval(() => {
            loadSession();
        }, 900000)
    }, []);

    const StatusColor = (status) => {
        if(status === 'pending'){
            return '#c7c73f';
        } else if(status === 'cancelled'){
            return 'red';
        } else {
            return 'green';
        }
    }

    const cancelSession = async (session) => {
        const reqData = {
            status: 'cancelled',
            date: session.date,
            start_time: session.start_time,
            member: session.member.id,
            cancel_reason: 'Cancelled by Admin'
        }
        try {
            const {data} = await axios.put(api + '/ptsessions/' + session.id, reqData, {headers});
            setCancelPopup({visible: false, session: []});
            window.location.href = '/dashboard';
        } catch (err) {
            console.log(err.response);
        }
    }

    const sessionApproval = async (session) => {
        const reqData = {
            status: 'approved',
            date: session.date,
            start_time: session.start_time,
            member: session.member.id,
            cancel_reason: 'Cancelled by Admin'
        }
        try {
            const {data} = await axios.put(api + '/ptsessions/' + session.id, reqData, {headers});
            window.location.href = '/dashboard';
        } catch (err) {
            console.log(err.response);
        }
    }

    if(loading){
        return 'Loading...';
    }
    if(!sessions.length) {
        return '';
    }
    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Pending PT Sessions</h3>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Table responsive hover>
                        <thead>
                            <tr>
                            <th>Client</th>
                            <th>Preferred Time</th>
                            <th>Preffered Trainer</th>
                            <th>Status</th>
                            <th className="activity-button-col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sessions.map(session => (
                                <tr key={session.id}>
                                    <td>
                                        <div className="wh-profile">
                                            <div className="wh-caption">
                                                <p>
                                                    {session.member.name}<br/>
                                                    <small>{session.member.erpid}</small>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td><Moment format="Do MMMM">{session.date}</Moment> <br/> <small>({session.start_time ? tConvert(session.start_time) : 'N/A'} - {session.end_time ? tConvert(session.end_time) : 'N/A'})</small></td>
                                    <td>{session.trainer.name} #{session.trainer.username}</td>
                                    <td>
                                        <span style={{color: StatusColor(session.status), textTransform: 'capitalize'}}>{session.status}</span>
                                    </td>
                                    <td style={{display: 'flex'}}>
                                        {session.status === 'pending' ? (
                                        <>
                                        <button style={{marginRight: '5px'}} className="activity-button" onClick={() => setApprovePopup({visible: true, session: session})}>Approve</button>
                                        <SkyLightStateless
                                        className="approval-skylight"
                                        transitionDuration={0}
                                        isVisible={approvePopup.visible}
                                        onCloseClicked={() => {setApprovePopup({visible: false, session: []})}}
                                        >
                                            <div className="class-cancel-popup">
                                                <h3 style={{marginBottom: '20px'}}>Do you want to approve this session?</h3>
                                                <div className="cancel-button-popup">
                                                    <button className="cancel-submit" onClick={() => sessionApproval(session)}>Approve</button>
                                                    <button onClick={() => {setApprovePopup({visible: false, session: []})}}>No</button>
                                                </div>
                                            </div>
                                        </SkyLightStateless>
                                        </>
                                        ) : ''}
                                        {session.status === 'cancelled' ? '' : (
                                        <>
                                        <button className="activity-button" onClick={() => setCancelPopup({visible: true, session: session})}>Cancel</button>
                                        <SkyLightStateless
                                        className="cancel-skylight"
                                        transitionDuration={0}
                                        isVisible={cancelPopup.visible}
                                        onCloseClicked={() => {setCancelPopup({visible: false, session: []})}}
                                        >
                                            <div className="class-cancel-popup">
                                                <h3 style={{marginBottom: '20px'}}>Do you want to cancel this session?</h3>
                                                <div className="cancel-button-popup">
                                                    <button className="cancel-submit" onClick={() => cancelSession(session)}>Yes, Cancel</button>
                                                    <button onClick={() => {setCancelPopup({visible: false, session: []})}}>No</button>
                                                </div>
                                            </div>
                                        </SkyLightStateless>
                                        </>
                                        )}
                                    </td>
                                </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default PendingSessions
