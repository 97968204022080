import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import api from '../api';
import headers from '../utils/headers'

const AddClass = () => {
    const [trainers, setTrainers] = useState([]);
    const [id, setId] = useState();

    useEffect(() => {
        loadErpTrainers();
    }, []);

    const loadErpTrainers = async () => {
        try {
            const {data} = await axios.get('http://182.160.118.234:8989/zab/members?user_id=api4apps&user_pwd=api4apps&info=TrainersInfo');
            unregisteredTrainers(data.TrainersInfoList);
        } catch (err) {
            console.log(err.response);
        }
    }

    const unregisteredTrainers = async (erpdata) => {
        try {
            const {data} = await axios.get(api + '/users?role.name=trainer', {headers});
            const filteredTrainers = erpdata.filter(o1 => !(data.find(o2 => o1.TrainerId === o2.username)));
            setTrainers(filteredTrainers);
        } catch (err) {
            console.log(err.response);
        }
    }

    const CreateTrainer = async () => {
        try {
            const {data} = await axios.get('http://182.160.118.234:8989/zab/members?user_id=api4apps&user_pwd=api4apps&info=TrainersInfo&member=' + id);
            const trainerData = data.TrainersInfoList[0];
            try {
                const {data} = await axios.post(api + '/auth/local/register', {
                    username: trainerData.TrainerId,
                    email: trainerData.Email,
                    password: trainerData.Phone,
                    role: 'trainer',
                    name: trainerData.TrainerName
                }, {headers});
                window.location.href = '/trainers';
            } catch (err) {
                console.log(err.response);
            }
        } catch (err) {
            console.log(err.response);
        }
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="single-activity-header">
                            <h3 className="dash-title">Add New Trainer</h3>
                            <div className="add-emp-btn-group">
                                <button className="employee-add-btn" onClick={CreateTrainer}>
                                    Save
                                </button>
                                <Link className="employee-add-btn cancel" to="/trainers">
                                    Cancel
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Form style={{width: '100%'}}>
                        <Form.Group as={Row} className="emp-form-row">
                            <Col>
                                <Form.Label>Trainer</Form.Label>
                                <Form.Control as="select" onChange={(e) => setId(e.target.value)}>
                                    <option value="">Select Trainer</option>
                                    {trainers.map(( trainer ) => (
                                        <option value={trainer.TrainerId} key={trainer.TrainerId}>{trainer.TrainerName} #{trainer.TrainerId}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Form.Label>Note</Form.Label>
                                <p>Trainers will have their EID as the username and phone number as the password after registering to the admin panel.</p>
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default AddClass