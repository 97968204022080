import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { UseMembers } from "../contexts/MembersContext";
import Member from '../components/Member'

const Members = () => {
    const { members } = UseMembers();
    console.log(members);
    return (
        <React.Fragment>
            <div className="container-fluid">
                <Row>
                    <Col>
                        <div className="employee-title">
                            <h3 className="dash-title">Members List</h3>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="container-fluid dashboard-wrapper white-wrapper daily-activity-table">
                <Row>
                    <Table responsive hover>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th className="activity-button-col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member, index) => <Member member={member} key={index} />)}
                        </tbody>
                    </Table>
                </Row>
            </div>
        </React.Fragment>
    )
}

export default Members
